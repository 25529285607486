import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin } from "react-google-login";
import Cookie from "js-cookie";
import { register } from "../features/auth/authSlice";
import { getUserTabins } from "../features/tabin/tabinSlice";
import ReactGA from "react-ga4";
import { Mixpanel } from "../app/mixpanel-instance";
import { Helmet } from "react-helmet";

// import { getTabinCenterTabins } from "../features/tabin/tabinSlice";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENTID;

const env = process.env.REACT_APP_ENV;
console.log("env", env);

const TAG = "Login";
function Login() {
	const [userData, setUserData] = useState([]);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onSuccess = async (res) => {
		if (env === "development" || env === "DEV") {
			console.log(TAG + ".onSuccess");
			console.log("LOGIN SUCCESS! current user: ", res);
		}
		console.log("LOGIN SUCCESS! current user: ", res);
		const userData = {
			googleId: res.profileObj.googleId,
			givenName: res.profileObj.givenName,
			familyName: res.profileObj.familyName,
			email: res.profileObj.email,
			imageUrl: res.profileObj.imageUrl,
			admin: false,
			paid: false,
		};
		if (env === "development" || env === "DEV") {
			console.log("userdata", userData);
			console.log("googleid", userData.googleId);
		}
		// register user to backend
		await dispatch(register(userData));
		setUserData(userData);

		// get user tabins
		await dispatch(getUserTabins(userData.googleId));
		// get tabin center page tabins
		// await dispatch(getTabinCenterTabins());

		let user = JSON.parse(Cookie.get("user"));
		if (env === "development" || env === "DEV") {
			console.log("_id", user._id);
		}
		ReactGA.event({
			category: "User Login",
			action: "Google Account Sign in",
			label: "Google Account Sign in",
			value: res.profileObj.googleId,
		});
		Mixpanel.track("User Login", {
			user: user._id,
		});
		Mixpanel.identify(user._id);
	};

	const { isLoading, isError, isSuccess, isSignedIn, message } = useSelector(
		(state) => state.auth
	);

	const onFailure = (res) => {
		let cookie_distinct_id = Cookie.get("distinct_id");
		Mixpanel.track("User Login failed", {
			user: cookie_distinct_id,
		});
		if (env === "development" || env === "DEV") {
			console.log("LOGIN Failed! current user: ", res);
		}
	};

	useEffect(() => {
		if (isError) {
			console.log(message);
		}
		if (isSignedIn || userData.length !== 0) {
			navigate("/welcome-1");
		}

		Mixpanel.track_pageview();

		ReactGA.send({
			hitType: "pageview",
			page: "/login",
			title: "Login",
		});
	}, [
		userData,
		isLoading,
		isError,
		isSuccess,
		isSignedIn,
		message,
		navigate,
		dispatch,
	]);

	// useEffect(() => {
	// 	function start() {
	// 		// Check if gapi.auth2 is already initialized
	// 		if (!gapi.auth2.getAuthInstance()) {
	// 			gapi.auth2
	// 				.init({
	// 					clientId: CLIENT_ID,
	// 					scope: "profile",
	// 				})
	// 				.then((authInstance) => {
	// 					// Sign in or fetch the current user
	// 					authInstance.signIn().then((res) => {
	// 						onSuccess(res);
	// 					});
	// 				});
	// 		} else {
	// 			// gapi.auth2 is already initialized, so use getAuthInstance to get the signed-in user
	// 			const authInstance = gapi.auth2.getAuthInstance();
	// 			if (authInstance.isSignedIn.get()) {
	// 				const user = authInstance.currentUser.get();
	// 				const profile = user.getBasicProfile();
	// 				const res = {
	// 					profileObj: {
	// 						googleId: profile.getId(),
	// 						givenName: profile.getGivenName(),
	// 						familyName: profile.getFamilyName(),
	// 						email: profile.getEmail(),
	// 						imageUrl: profile.getImageUrl(),
	// 					},
	// 				};
	// 				onSuccess(res);
	// 			} else {
	// 				// If the user is not signed in, prompt them to sign in
	// 				authInstance.signIn().then((res) => {
	// 					onSuccess(res);
	// 				});
	// 			}
	// 		}
	// 	}

	// 	// Load the Google API
	// 	gapi.load("auth2", start);
	// }, []); // Ensure this only runs once by providing an empty dependency array;

	return (
		<div>
			<Helmet>
				<title>
					Tabin | Login - Context Switching Made Easy To Increase Your Work
					Productivity
				</title>
				<link rel='canonical' href='https://tabin.io/login' />
			</Helmet>
			<div className={`flex sm:mb-8 mb-6 xs:p-1 lg:pl-[200px]`}>
				<div className='justify-center items-center pt-[20px] xs:grid xs:pl-[50px] sm:pt-[150px] sm:inline-flex xs:w-[100%] lg:w-[90%] '>
					<div className='flex justify-center items-center  bg-white w-[100%] xs:order-last sm:-order-1 sm:border-none'>
						<span className='flex'>
							<img
								src='tabin_people_laptop2.png'
								alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
								className='h-[auto] w-[450px] sm:pr-10 '
							/>
						</span>
					</div>
					<div className='flex h-[100%] w-[100%] justify-center'>
						<div className='rs:hidden sm:inline-block h-[75%] w-[5%]'>
							<div className='block h-[50%] w-[100%] bg-[#FF6E8B]'></div>
							<div className='block h-[50%] w-[100%] bg-[#AAF0DA]'></div>
						</div>
						<div className='inline-block w-[100%] justify-center items-center bg-white sm:mt-[120px]'>
							<span className='flex justify-center w-[100%] text-[40px] font-semibold flexcenter pt-[30px] pb-[15px]'>
								Sign in
							</span>
							<div className='googlebtncontainer'>
								<GoogleLogin
									clientId={CLIENT_ID}
									render={(renderProps) => (
										<button
											className='googlebtn '
											onClick={renderProps.onClick}
											disabled={renderProps.disabled}
										>
											<span className='googlelogowhitebox'>
												<img
													className='googlelogo'
													src='google_logo.png'
													alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
												/>
											</span>
											LOGIN WITH GOOGLE
										</button>
									)}
									buttonText='Signin with Google'
									onSuccess={onSuccess}
									onFailure={onFailure}
									cookiePolicy={"single_host_origin"}
									isSignedIn={false}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
