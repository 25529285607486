import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin } from "react-google-login";
import Cookie from "js-cookie";
import { register } from "../features/auth/authSlice";
import { getUserTabins } from "../features/tabin/tabinSlice";
import ReactGA from "react-ga4";
import { Mixpanel } from "../app/mixpanel-instance";
import { Helmet } from "react-helmet";

// import { getTabinCenterTabins } from "../features/tabin/tabinSlice";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENTID;
const APP_DOMAIN = process.env.REACT_APP_DOMAIN;

const env = process.env.REACT_APP_ENV;
console.log("env", env);

const SCOPES = "email profile";
const GOOGLE_USER_INFO_API = `https://www.googleapis.com/oauth2/v3/userinfo`;

const TAG = "Login";
const Logintmp = () => {
	const [userData, setUserData] = useState([]);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onSuccess = async (res) => {
		if (env === "development" || env === "DEV") {
			console.log(TAG + ".onSuccess");
			console.log("LOGIN SUCCESS! current user: ", res);
		}

		const userobj = {
			googleId: res.profileObj.googleId,
			givenName: res.profileObj.givenName,
			familyName: res.profileObj.familyName,
			email: res.profileObj.email,
			imageUrl: res.profileObj.imageUrl,
			admin: false,
			paid: false,
		};
		if (env === "development" || env === "DEV") {
			console.log("userdata", userobj);
			console.log("googleid", userobj.googleId);
		}
		// register user to backend
		await dispatch(register(userobj));
		setUserData(userobj);

		// get user tabins
		await dispatch(getUserTabins(userData.googleId));
		// get tabin center page tabins
		// await dispatch(getTabinCenterTabins());

		let user = JSON.parse(Cookie.get("user"));
		if (env === "development" || env === "DEV") {
			console.log("_id", user._id);
		}
		ReactGA.event({
			category: "User Login",
			action: "Google Account Sign in",
			label: "Google Account Sign in",
			value: res.profileObj.googleId,
		});
		Mixpanel.track("User Login", {
			user: user._id,
		});
		Mixpanel.identify(user._id);
	};

	const { isLoading, isError, isSuccess, isSignedIn, message } = useSelector(
		(state) => state.auth
	);

	const onFailure = (res) => {
		let cookie_distinct_id = Cookie.get("distinct_id");
		Mixpanel.track("User Login failed", {
			user: cookie_distinct_id,
		});
		if (env === "development" || env === "DEV") {
			console.log("LOGIN Failed! current user: ", res);
		}
	};

	const oauthSignIn = () => {
		// Google's OAuth 2.0 endpoint for requesting an access token
		var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

		// Create <form> element to submit parameters to OAuth 2.0 endpoint.
		var form = document.createElement("form");
		form.setAttribute("method", "GET"); // Send as a GET request.
		form.setAttribute("action", oauth2Endpoint);

		// Parameters to pass to OAuth 2.0 endpoint.
		var params = {
			client_id: CLIENT_ID,
			redirect_uri: APP_DOMAIN + "/login",
			response_type: "token",
			scope: SCOPES,
			include_granted_scopes: "true",
			state: "pass-through value",
			prompt: "consent",
		};

		// Add form parameters as hidden input values.
		for (var p in params) {
			var input = document.createElement("input");
			input.setAttribute("type", "hidden");
			input.setAttribute("name", p);
			input.setAttribute("value", params[p]);
			form.appendChild(input);
		}

		// Add form to page and submit it to open the OAuth 2.0 endpoint.
		document.body.appendChild(form);
		form.submit();
	};

	const getData = async () => {
		const hash = window.location.hash;
		const params = new URLSearchParams(hash.substring(1)); // Remove the '#' at the start
		const accessToken = params.get("access_token");

		if (accessToken) {
			window.history.replaceState(null, null, " ");
			// Fetch calendar events for tomorrow
			console.log("getting user info");
			try {
				const userInfoResponse = await fetch(GOOGLE_USER_INFO_API, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});
				const profileData = await userInfoResponse.json();
				console.log("profile Data", profileData);

				const userobj = {
					googleId: profileData.sub,
					givenName: profileData.given_name,
					familyName: profileData.family_name,
					email: profileData.email,
					imageUrl: profileData.picture,
					admin: false,
					paid: false,
				};

				console.log("userobj", userobj);

				setUserData(userobj);

				if (env === "development" || env === "DEV") {
					console.log("userdata", userobj);
					console.log("googleid", userobj.googleId);
				}
				// register user to backend
				await dispatch(register(userobj));
				setUserData(userobj);

				// get user tabins
				await dispatch(getUserTabins(userobj.googleId));
				// get tabin center page tabins
				// await dispatch(getTabinCenterTabins());

				let user = JSON.parse(Cookie.get("user"));
				if (env === "development" || env === "DEV") {
					console.log("_id", user._id);
				}
				ReactGA.event({
					category: "User Login",
					action: "Google Account Sign in",
					label: "Google Account Sign in",
					value: userobj.googleId,
				});
				Mixpanel.track("User Login", {
					user: user._id,
				});
				Mixpanel.identify(user._id);
			} catch (error) {
				console.error("Error fetching calendar events: ", error);
			}
		}
	};

	useEffect(() => {
		getData();
	}, []);

	useEffect(() => {
		if (isError) {
			console.log(message);
		}
		if (isSignedIn || userData.length !== 0) {
			navigate("/welcome-1");
		}

		Mixpanel.track_pageview();

		ReactGA.send({
			hitType: "pageview",
			page: "/login",
			title: "Login",
		});
	}, [
		userData,
		isLoading,
		isError,
		isSuccess,
		isSignedIn,
		message,
		navigate,
		dispatch,
	]);

	return (
		<div>
			<Helmet>
				<title>
					Tabin | Login - Context Switching Made Easy To Increase Your Work
					Productivity
				</title>
				<link rel='canonical' href='https://tabin.io/login' />
			</Helmet>
			<div className={`flex sm:mb-8 mb-6 xs:p-1 lg:pl-[200px]`}>
				<div className='justify-center items-center pt-[20px] xs:grid xs:pl-[50px] sm:pt-[150px] sm:inline-flex xs:w-[100%] lg:w-[90%] '>
					<div className='flex justify-center items-center  bg-white w-[100%] xs:order-last sm:-order-1 sm:border-none'>
						<span className='sm:mt-14'>
							<img
								src='tabin_people_laptop2.png'
								alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
								className='h-[auto] w-[450px] sm:pr-10 '
							/>
						</span>
					</div>
					<div className='flex h-[100%] w-[100%] justify-center  items-center sm:mb-10'>
						<div className='rs:hidden sm:inline-block h-[75%] w-[5%]'>
							<div className='block h-[50%] w-[100%] bg-[#FF6E8B]'></div>
							<div className='block h-[50%] w-[100%] bg-[#AAF0DA]'></div>
						</div>
						<div className='inline-block w-[100%] bg-white '>
							<span className='flex justify-center w-[100%] text-[40px] font-semibold flexcenter pt-[30px] pb-[15px]'>
								Sign in
							</span>
							<div className='googlebtncontainer'>
								<button className='googlebtn ' onClick={oauthSignIn}>
									<span className='googlelogowhitebox'>
										<img
											className='googlelogo'
											src='google_logo.png'
											alt='Context Switching App to increase work productivity, schedule tabins and get notification, single link to multiple links, take notes while attending meetings, save time and increase focus'
										/>
									</span>
									LOGIN WITH GOOGLE
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Logintmp;
